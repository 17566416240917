<template>
  <div>
    <v-toolbar color="primary" :dark="true">
      <v-app-bar-nav-icon v-if="isBack" :to="link" icon="mdi-arrow-left"></v-app-bar-nav-icon>
      <v-toolbar-title>BECO Drive</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="toggleTheme">
        <v-icon>mdi-bell</v-icon>
      </v-btn>
      <v-btn icon @click="commonStore.bgLoadToggle">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <v-menu location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>Reload data</v-list-item>
          <v-list-item>Manage Account</v-list-item>
          <v-list-item  @click="toggleTheme">  <v-icon>mdi-theme-light-dark</v-icon>App Settings</v-list-item>

          <v-list-item to="/">Log Out</v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-progress-linear
      buffer-value="50"
      color="lime"
      :indeterminate="commonStore.isBGLoading"
    ></v-progress-linear>
  </div>
</template>

<script setup>
import { useCommonStore } from "@/store/common-store";
import {defineProps} from 'vue'
const commonStore = useCommonStore();

import { useTheme } from "vuetify";

const theme = useTheme();
function toggleTheme() {
  theme.global.name = theme.global.current.dark ? "light" : "dark";
}
//props
defineProps({
  isBack: Boolean,
  link: String  
})
</script>

<style></style>
