<template>
  <div>
    <v-card class="mx-auto" title="Please fill all fields">
      <v-card-item dense>
        <form class="my-2">
          <v-select
            v-model="form.planid"
            :items="packges"
            item-title="txt"
            item-value="id"
            variant="outlined"
            label="Lessons Package"
          >
          </v-select>
          <v-text-field
            v-model="form.nameOnCard"
            label="Name (as it appears on the card)"
            variant="outlined"
          ></v-text-field>
          <v-text-field
            v-model="form.cardNumber"
            label="Card Number"
            variant="outlined"
          ></v-text-field>
          <v-text-field
            v-model="form.expDate"
            label="Expiary Date"
            variant="outlined"
          ></v-text-field>
          <v-text-field
            v-model="form.cvv"
            label="Security Code or CVV2"
            variant="outlined"
          ></v-text-field>
          <v-text-field
            v-model="form.hodlerName"
            label="Card Holder's Name"
            variant="outlined"
          ></v-text-field>
          <v-text-field
            v-model="form.addLin1"
            label="First Line of Address"
            variant="outlined"
          ></v-text-field>
          <v-text-field
            v-model="form.pc"
            label="Postcode"
            variant="outlined"
          ></v-text-field>
          <v-text-field
            label="Total"
            variant="outlined"
            readonly
            v-model="payprice"
          ></v-text-field>
        </form>
      </v-card-item>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn prepend-icon="mdi-lock" color="success" variant="flat"
          >Pay</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script setup>
import { computed, reactive } from "vue";

const form = reactive({
  planid: null,
  nameOnCard: null,
  cardNumber: null,
  expDate: null,
  cvv: null,
  hodlerName: null,
  addLin1: null,
  pc: null,
});
const packges = [
  { id: 200, txt: "1 Hour Manual Lesson", price: 25 },
  { id: 201, txt: "2 Hour Manual Lessons", price: 50 },
  { id: 202, txt: "5 Hour Manual Lessons", price: 120 },
  { id: 203, txt: "10 Hour Manual Lessons", price: 235 },
  { id: 208, txt: "2 Hour Manual Lessons", price: 49.5 },
  { id: 209, txt: "3 Hour Manual Lessons", price: 72 },
];
const payprice = computed(() => {
  if (form.planid) {
    let cost = packges.filter(function (rec) {
      return rec.id == 200;
    });
    if (cost.length > 0) {
      return "£ " + cost[0].price;
    } else {
      return "£ 00.00";
    }
  }
  return "00.00";
});
</script>
