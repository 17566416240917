<template>
  <div>
    <v-card
      class="mx-auto"
      prepend-icon="mdi-school"
      :subtitle="storeLeanerData.schoolinfo.address"
    >
      <template v-slot:title>
        <span class="font-weight-black">{{
          storeLeanerData.schoolinfo.name
        }}</span>
      </template>
      <template v-slot:prepend>
        <v-avatar color="blue-darken-2" size="64">
          <v-icon icon="mdi-school" size="60"></v-icon>
        </v-avatar>
      </template>

      <v-card-text class="bg-surface-light pt-4"
        >
        <p><span>Phone:</span> <a :href="`tel:${storeLeanerData.schoolinfo.phone}`">{{ storeLeanerData.schoolinfo.phone }}</a></p>
        <p><span>Email:</span> <a :href="`mailto:${storeLeanerData.schoolinfo.email}`">{{ storeLeanerData.schoolinfo.email }}</a></p>
        <p><span>Website:</span> <a :href="`${storeLeanerData.schoolinfo.website}`">{{ storeLeanerData.schoolinfo.website }}</a></p>
        <p><span>Address:</span> {{ storeLeanerData.schoolinfo.address }}</p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import { useLearnerStore } from "@/store/learner-store";
const storeLeanerData = useLearnerStore();
</script>

<style lang="scss" scoped>
p{
    line-height: 2rem;
    span{
        font-weight: 600;
    }
}
a{
    text-decoration: none;
    background:
    linear-gradient(
      to right,
      rgba(100, 200, 200, 1),
      rgba(100, 200, 200, 1)
    ),
    linear-gradient(
      to right,
      rgba(255, 0, 0, 1),
      rgba(255, 0, 180, 1),
      rgba(0, 100, 200, 1)
    );
    background-size: 100% 2px, 0 2px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;

}</style>
