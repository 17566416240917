import { defineStore } from 'pinia'
//doing in option style for simplycity

export const useCommonStore = defineStore('common', {
    state: () => ({
        isBGSpinner: false,         
        }),
    
    actions: {
        bgLoading(){
            this.isBGSpinner = true
        },
        bgLoaded(){
            this.isBGSpinner = false
        }, 
        bgLoadToggle() {
            this.isBGSpinner = !this.isBGSpinner 
        }     
    },
    getters: {
        isBGLoading: (state) => state.isBGSpinner,
    },
    persist: true
  })