<template>
  <div>
    <v-card class="mx-auto">
      <template v-slot:prepend>
        <v-icon icon="mdi-book-open-variant-outline" size="64"></v-icon>
      </template>
      <template v-slot:title>
        <span class="font-weight-black">Ref#: C1001</span>
      </template>
      <template v-slot:subtitle>
        <span class="">Incident On: 03-10-2023 13:10:00</span>
      </template>

      <v-card-text class="bg-surface-dark pt-4">
        <p><span>Complaint State:</span> {{ status }}</p>
        <p><span>Complaint about:</span> Mr John Doe</p>
        <p><span>Tried to Resolvde Directly:</span> No</p>
        <p>
          <span>Description: </span> Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Commodi, ratione debitis quis est labore
          voluptatibus! Eaque cupiditate minima, at placeat totam, magni
          doloremque veniam neque porro libero rerum unde voluptatem!
        </p>
      </v-card-text>
      <v-card-actions>
      <v-btn
        color="orange-lighten-2"
        text="Updates"
      ></v-btn>

      <v-spacer></v-spacer>

      <v-btn
        :icon="show ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        @click="show = !show"
      ></v-btn>
    </v-card-actions>
      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>
          <v-card-text class="bg-surface-light pt-4">
            <p>
              <span>Update:</span> Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Commodi, ratione debitis quis est labore
              voluptatibus! Eaque cupiditate minima, at placeat totam, magni
              doloremque veniam neque porro libero rerum unde voluptatem!
            </p>
            <p><span>Date & Time: </span>05-03-2024 13:50</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text class="bg-surface-dark pt-4">
            <p>
              <span>Update:</span> Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Commodi, ratione debitis quis est labore
              voluptatibus! Eaque cupiditate minima, at placeat totam, magni
              doloremque veniam neque porro libero rerum unde voluptatem!
            </p>
            <p><span>Date & Time: </span>05-03-2024 13:50</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text class="bg-surface-light pt-4">
            <p>
              <span>Update:</span> Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Commodi, ratione debitis quis est labore
              voluptatibus! Eaque cupiditate minima, at placeat totam, magni
              doloremque veniam neque porro libero rerum unde voluptatem!
            </p>
            <p><span>Date & Time: </span>05-03-2024 13:50</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text class="bg-surface-dark pt-4">
            <p>
              <span>Update:</span> Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Commodi, ratione debitis quis est labore
              voluptatibus! Eaque cupiditate minima, at placeat totam, magni
              doloremque veniam neque porro libero rerum unde voluptatem!
            </p>
            <p><span>Date & Time: </span>05-03-2024 13:50</p>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
const show = ref(false);
defineProps({
  status: String
})
</script>

<style lang="scss" scoped>
p{
margin-top: 5px;
  span{
    font-weight: 600;
  }
}
</style>
