<template>
  <div>
    <v-card class="mx-auto" title="Please fill all fields">
      <v-card-item dense> </v-card-item>
      <v-card-item>
        <template v-slot:title>
          <span
            >Pickup Location
            <v-icon icon="mdi-map-marker-up" color="red"></v-icon
          ></span>
        </template>
        <v-text-field
          v-model="form.pickupadd"
          label="First line of address"
          variant="outlined"
          density="dense"
        ></v-text-field>
        <v-text-field
          v-model="form.pickuppc"
          label="Postcode"
          variant="outlined"
          density="dense"
        ></v-text-field>
      </v-card-item>
      <v-card-item>
        <template v-slot:title>
          <span
            >Drop Off Location
            <v-icon icon="mdi-map-marker-down" color="red"></v-icon
          ></span>
        </template>
        <v-text-field
          v-model="form.dropadd"
          label="First line of address"
          variant="outlined"
          density="dense"
        ></v-text-field>
        <v-text-field
          v-model="form.droppc"
          label="Postcode"
          variant="outlined"
          density="dense"
        ></v-text-field>
      </v-card-item>
      <v-card-item class="align-center">
        <!-- allowed-dates="" -->
        <v-date-picker
          color="primary"
          v-model="form.bdate"
          max="2024-07-26"
          min="2024-04-26"
          show-adjacent-months
        ></v-date-picker>
      </v-card-item>
      <v-card-item>
        <div style="font-size: 30px; width: 100%">
          <VueScrollPicker v-model="form.btime" :options="pickOpt" />
        </div>
      </v-card-item>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn prepend-icon="mdi-lock" color="primary" variant="flat"
          >Book Lesson</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script setup>
import { reactive } from "vue";
import { VueScrollPicker } from "vue-scroll-picker";

const form = reactive({
  creditid: null,
  pickupadd: null,
  pickuppc: null,
  dropadd: null,
  droppc: null,
  bdate: null,
  btime: null,
});
const pickOpt = [
  { value: "8:00", name: "8:00 - 9:00" },
  { value: "8:15", name: "8:15 - 9:15" },
  { value: "8:30", name: "8:30 - 9:30" },
  { value: "8:45", name: "8:45 - 9:45" },
  { value: "9:00", name: "9:00 - 10:00" },
  { value: "9:15", name: "9:15 - 10:15", disabled: true },
  { value: "9:30", name: "9:30 - 10:30" },
  { value: "9:45", name: "9:45 - 10:45" },
  { value: "10:00", name: "10:00 - 11:00" },
  { value: "10:15", name: "10:15 - 11:15" },
  { value: "10:30", name: "10:30 - 11:30" },
  { value: "10:45", name: "10:45 - 11:45" },
  { value: "11:00", name: "11:00 - 12:00", disabled: true },
  { value: "11:15", name: "11:15 - 12:15" },
  { value: "11:30", name: "11:30 - 12:30" },
  { value: "11:45", name: "11:45 - 12:45" },
  { value: "12:00", name: "12:00 - 13:00" },
  { value: "12:15", name: "12:15 - 13:15" },
  { value: "12:30", name: "12:30 - 13:30", disabled: true },
  { value: "12:45", name: "12:45 - 13:45", disabled: true },
  { value: "13:00", name: "13:00 - 14:00", disabled: true },
  { value: "13:15", name: "13:15 - 14:15" },
  { value: "13:30", name: "13:30 - 14:30" },
  { value: "13:45", name: "13:45 - 14:45" },
  { value: "14:00", name: "14:00 - 15:00" },
  { value: "14:15", name: "14:15 - 15:15" },
  { value: "14:30", name: "14:30 - 15:30", disabled: true },
  { value: "14:45", name: "14:45 - 15:45" },
  { value: "15:00", name: "15:00 - 16:00", disabled: true },
  { value: "15:15", name: "15:15 - 16:15" },
  { value: "15:30", name: "15:30 - 16:30", disabled: true },
  { value: "15:45", name: "15:45 - 16:45" },
  { value: "16:00", name: "16:00 - 17:00" },
  { value: "16:15", name: "16:15 - 17:15" },
  { value: "16:30", name: "16:30 - 17:30" },
  { value: "16:45", name: "16:45 - 17:45" },
  { value: "17:00", name: "17:00 - 18:00" },
  { value: "17:15", name: "17:15 - 18:15" },
  { value: "17:30", name: "17:30 - 18:30" },
  { value: "17:45", name: "17:45 - 18:45" },
  { value: "18:00", name: "18:00 - 19:00" },
  { value: "18:15", name: "18:15 - 19:15" },
  { value: "18:30", name: "18:30 - 19:30" },
  { value: "18:45", name: "18:45 - 19:45" },
  { value: "19:00", name: "19:00 - 20:00" },
  { value: "19:15", name: "19:15 - 20:15" },
  { value: "19:30", name: "19:30 - 20:30" },
  { value: "19:45", name: "19:45 - 20:45" },
];
</script>
<style src="vue-scroll-picker/lib/style.css"></style>
