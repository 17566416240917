<template>
  <router-view v-slot="{ Component,route }">
  <transition 
    :enter-active-class="route.meta.enterClass"
    :leave-active-class="route.meta.leaveClass"
    mode="in-out">
    <component :is="Component" />
  </transition></router-view>
</template>

<style lang="scss">


</style>
