<template>
  <v-card
    class="mx-auto"
    subtitle="Instructor: Mr John Doe"
    title="Rate Lesson on 04-03-24"
    width="100%"
  >
    <v-card-item
      >This feedback help us to improve teaching and lessons
      quality.</v-card-item
    >
    <v-divider></v-divider>
    <v-card-item>
      <div>
        <p>
          1. How distracted was the instructor with their personal work, calls
          and other agendas?
        </p>
        <div class="text-center mt-5">
          <v-rating
            v-model="rating1"
            :item-labels="labels"
            size="x-large"
            active-color="primary"
            color="orange-lighten-1"
          >
            <template v-slot:item-label="props">
              <span
                :class="`text-${colours[props.index]}`"
                class="font-weight-black text-caption"
              >
                {{ props.label }}
              </span>
            </template>
          </v-rating>
        </div>
      </div>
    </v-card-item>
    <v-divider></v-divider>

    <v-card-item>
      <div>
        <p>
          2. How practical, suitable, or comfortable is the vehicle you are
          learning in?
        </p>
        <div class="text-center mt-5">
          <v-rating
            v-model="rating2"
            :item-labels="labels"
            size="x-large"
            active-color="primary"
            color="orange-lighten-1"
          >
            <template v-slot:item-label="props">
              <span
                :class="`text-${colours[props.index]}`"
                class="font-weight-black text-caption"
              >
                {{ props.label }}
              </span>
            </template>
          </v-rating>
        </div>
      </div>
    </v-card-item>
    <v-divider></v-divider>
    <v-card-item>
      <div>
        <p>3. How presentable was your instructor?</p>
        <div class="text-center mt-5">
          <v-rating
            v-model="rating3"
            :item-labels="labels"
            size="x-large"
            active-color="primary"
            color="orange-lighten-1"
          >
            <template v-slot:item-label="props">
              <span
                :class="`text-${colours[props.index]}`"
                class="font-weight-black text-caption"
              >
                {{ props.label }}
              </span>
            </template>
          </v-rating>
        </div>
      </div>
    </v-card-item>

    <v-card-actions>
      <v-btn
        variant="flat"
        text="Save"
        block
        color="primary"
        to="/feedbackview"
      >
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { ref } from "vue";

const rating1 = ref(0);
const rating2 = ref(0);
const rating3 = ref(0);
const colours = ["red", "orange", "yellow-darken-2", "lime", "green"];
const labels = ["bad", "not bad", "ok", "good", "great"];
</script>

<style scoped>
p {
  font-weight: 600;
}
</style>
