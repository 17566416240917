import { defineStore } from "pinia";
//doing in option style for simplycity

export const useLearnerStore = defineStore("learner", {
  state: () => ({
    count: 0,
    name: "Eduardo",
    isFullySetup: true,
    serverURL: "",
    schoolInfo: {
      name: "RED",
      phone: "01230 456 789",
      email: "info@red.co.uk",
      address: "261 Low Lane Horsforth, Leeds, West Yorkshire, LS18 5NY",
      website: "https://red.co.uk"
    },
    instructorInfo:{
      name: "Mr John Doe",
      phone: "0777 456 789",
      email: "johndoe@red.co.uk",

    },
    learnerInfo: {
      title: "Mr",
      name: "Eduardo",
      learnerID: 1005,
      lessonsType: "Manual"
    },
    learnerCreditInfo: {
      available: 3,
      used: 7
    },
    learnerBookingInfo: {
      completed: 4,
      upcoming: 2,
      canceled: 1,
    }
  }),

  actions: {
    incriment() {
      this.count++;
    },
  },
  getters: {
    doubleCount: (state) => {
      return state.count * 2;
    },
    avaialabeLessons: (state) => {
      return state.learnerCreditInfo.available;
    },
    schoolinfo: (state) => state.schoolInfo,
  },
  persist: true,
});
