<template>
    <div>
      <v-app>
        <AppToolBar :isBack="true" link="/bookingsdash" />
        <v-main>
          <v-container>
            <v-row dense>             
              <v-col cols="12">
                <v-card
                  class="mx-auto"                        
                  subtitle="Please contact driving school for queires"
                  title="Previous payments"                  
                  width="100%"
                  
                ><v-card-text></v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" v-for="booking in ratingPendingBookings" :key="booking.id">
                <v-card
                  class="mx-auto"
                  variant="outlined"
                  :subtitle="booking.sub"
                  :title="booking.text"                  
                  width="100%"
                  :disabled="booking.isReadonly"

                >
                  <template v-slot:prepend>
                    <v-icon
                      :icon="booking.ico"
                      color="primary"
                      size="56"
                    ></v-icon>
                  </template>
                </v-card>
              </v-col>              
            </v-row>
          </v-container>
        </v-main>
      </v-app>
    </div>
  </template>
  
  <script setup>
  import AppToolBar from "@/components/AppToolBar";

  const ratingPendingBookings =  [
    {
      id: 1,
      text: "On 14-04-2024 16:30",
      sub: "£50.00",
      bookingID: 1001,
      ico: "mdi-numeric-2",
      isReadonly: false,
    },
    {
      id: 2,
      text: "On 02-04-2024 16:30",
      sub: "£190.00",
      bookingID: 1001,
      ico: "mdi-numeric-5",
      isReadonly: false,
    },
    {
      id: 3,
      text: "On 20-03-2024 16:30",
      sub: "£30.00",
      bookingID: 1001,
      ico: "mdi-numeric-1",
      isReadonly: false,
    },
   
  ];
  </script>
  
  <style lang="scss" scoped></style>
  