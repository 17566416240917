<template>
  <div class="row" justify="center">
    <v-col md="12">
      <v-breadcrumbs :items="items">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
      <v-sheet color="white" elevation="1" class="pa-5">
        <h2>Complaint Form</h2>
        <v-divider class="mt-3 mb-3"></v-divider>
        <v-form>
          <v-row justify="center">
            <v-col md="4">
              <!-- Complainant will be here -->
              <!-- Complainant is the lerner that use this app -->
              <v-select
                v-model="form.complaintType"
                :items="complaintParties"
                label="Complaint Type"
                item-title="txt"
                item-value="id"
                variant="outlined"
                required
              ></v-select>

              <v-expand-transition>
                <div v-if="complainantADI">
                  <v-text-field
                    v-model="form.complainant"
                    label="Complainant Instructor"
                    variant="outlined"
                    readonly
                    append-icon="mdi-account-tie"
                  ></v-text-field>
                  <!-- {
                        :error-messages="complainantError"
                        @input="v$.form.complainantID.$touch()"
                      @blur="v$.form.complainantID.$touch()"
                      @click:append="selectComplainantADI()"
                    } -->
                  <SelectFromTableDialog
                    :dialog="complainantADIDialog"
                    dialog-title="Instructor"
                    :headers="tableHeaders"
                    :dataarray="tableData"
                    type="adi"
                    @closed="complainantADIDialog = false"
                    @choosen="ComplainantADISelected($event)"
                  />
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="complainantThirdP">
                  <v-textarea
                    value="(Pleasenote down all the information about this Third Party in the description below including their Full Name, Organisation Name, E-mail, Contact Numbers, Full Address and the Complaint.)"
                    label="Third Party"
                    variant="outlined"
                    disabled
                    no-resize
                    rows="3"
                  ></v-textarea>
                  <span></span>
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="complainantOther">
                  <v-textarea
                    value="Please provide all information in description) Please add complainant information in description (E-mail, Contact Number and Address)"
                    label="Other"
                    variant="outlined"
                    disabled
                    no-resize
                    rows="3"
                  ></v-textarea>
                  <span></span>
                </div>
              </v-expand-transition>
              <!-- Complainee will be here -->
              <v-expand-transition>
                <div v-if="complaineeLearner">
                  <v-text-field
                    v-model="form.complainee"
                    label="Complainee Learner"
                    variant="outlined"
                    readonly
                    :error-messages="complaineeError"
                    @input="v$.form.complaineeID.$touch()"
                    @blur="v$.form.complaineeID.$touch()"
                    append-icon="mdi-account-plus"
                    @click:append="selectComplaineeLearner()"
                  ></v-text-field>
                  <SelectFromTableDialog
                    :dialog="complaineeLearnerDialog"
                    dialog-title="Learner"
                    :headers="tableHeaders"
                    :dataarray="tableData"
                    type="learner"
                    @closed="complaineeLearnerDialog = false"
                    @choosen="ComplaineeLearnerSelected($event)"
                  />
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="complaineeADI">
                  <v-text-field
                    v-model="form.complainee"
                    label="Complainee Instructor"
                    variant="outlined"
                    readonly
                    :error-messages="complaineeError"
                    @input="v$.form.complaineeID.$touch()"
                    @blur="v$.form.complaineeID.$touch()"
                    append-icon="mdi-account-tie"
                    @click:append="selectComplaineeADI()"
                  ></v-text-field>
                  <SelectFromTableDialog
                    :dialog="complaineeADIDialog"
                    dialog-title="Instructor"
                    :headers="tableHeaders"
                    :dataarray="tableData"
                    type="adi"
                    @closed="complaineeADIDialog = false"
                    @choosen="ComplaineeADISelected($event)"
                  />
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="complaineeThirdP">
                  <v-textarea
                    value="(Pleasenote down all the information about this Third Party in the description below including their Full Name, Organisation Name, E-mail, Contact Numbers, Full Address and the Complaint.)"
                    label="Third Party"
                    variant="outlined"
                    disabled
                    no-resize
                    rows="3"
                  ></v-textarea>
                  <span></span>
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="complaineeSchool">
                  <v-text-field
                    value="Driving School"
                    label="Complainee"
                    variant="outlined"
                    disabled
                  ></v-text-field>
                  <span></span>
                </div>
              </v-expand-transition>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col md="2"
              ><v-text-field
                v-model="form.iDate"
                label="Incident Date"
                variant="outlined"
                type="date"
                :error-messages="compDateError"
                @input="v$.form.iDate.$touch()"
                @blur="v$.form.iDate.$touch()"
              ></v-text-field>
            </v-col>

            <v-col md="2">
              <v-text-field
                v-model="form.iTime"
                label="Incident Time"
                variant="outlined"
                type="time"
                :error-messages="compTimeError"
                @input="v$.form.iTime.$touch()"
                @blur="v$.form.iTime.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col md="4"
              ><v-textarea
                v-model="form.complainDesc"
                variant="outlined"
                name="input-7-4"
                label="Complaint Description"
                placeholder="Please Describe Complaint (Max. 1600 characters)"
                counter="1600"
                clearable
                rows="4"
                auto-grow
                value=""
                :error-messages="compDescError"
                @input="v$.form.complainDesc.$touch()"
                @blur="v$.form.complainDesc.$touch()"
              ></v-textarea>
              <v-select
                v-model="form.selfResolve"
                :items="selfResolveItems"
                label="Did complainant discuss the issue with complainee?"
                variant="outlined"
              ></v-select></v-col
          ></v-row>
          <!-- following div will be displayed when self reolve  -->
          <v-expand-transition>
            <div v-if="form.selfResolve">
              <v-divider class="mb-5"></v-divider>
              <v-row justify="center">
                <v-col md="2">
                  <v-text-field
                    v-model="form.srDate"
                    label="Discussion Date With Complainee"
                    variant="outlined"
                    type="date"
                    :error-messages="srDateError"
                    @input="v$.form.srDate.$touch()"
                    @blur="v$.form.srDate.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col md="2">
                  <v-text-field
                    v-model="form.srTime"
                    label="Discussion Time With Complainee"
                    variant="outlined"
                    type="time"
                    :error-messages="srTimeError"
                    @input="v$.form.srTime.$touch()"
                    @blur="v$.form.srTime.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col md="4"
                  ><v-select
                    v-model="form.srSolution"
                    :items="selfResolveItems"
                    label="Did complainee offer any solution?"
                    variant="outlined"
                  ></v-select></v-col
              ></v-row>
              <v-row justify="center">
                <v-col md="4"
                  ><v-textarea
                    v-model="form.srReplyDesc"
                    variant="outlined"
                    name="input-7-4"
                    label="Complainee Reply Description"
                    placeholder="Please Describe Complainee Response (Max. 1600 characters)"
                    counter="1600"
                    clearable
                    rows="4"
                    auto-grow
                    value=""
                    :error-messages="srDescError"
                    @input="v$.form.srReplyDesc.$touch()"
                    @blur="v$.form.srReplyDesc.$touch()"
                  ></v-textarea></v-col
              ></v-row>
            </div>
          </v-expand-transition>

          <v-row justify="center">
            <v-btn color="primary" class="mb-5" @click="submitForm()"
              >Record Complaint</v-btn
            > </v-row
          >
        </v-form>
      </v-sheet>
    </v-col>
  </div>
</template>

<script setup>
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  minValue,
} from "@vuelidate/validators";
import { reactive } from "vue";

const selfResolveItems = [
  { text: "No", value: false },
  { text: "Yes", value: true },
];
const complaintParties = [ {
        "id": 1,
        "txt": "Learner about a Learner",
        "description": ""
      },
      {
        "id": 2,
        "txt": "Learner about an Instructor",
        "description": ""
      },
      {
        "id": 3,
        "txt": "Learner about Driving School",
        "description": ""
      },
      {
        "id": 4,
        "txt": "Learner about Third Party",
        "description": ""
      },  
      {
        "id": 18,
        "txt": "Any Other Complaints",
        "description": ""
      }]

const form = reactive({
  complaintType: null,
  //just for display
  complainant: null,
  complainee: null,

  //real data we need it ID
  complainantID: null,
  complaineeID: null,

  //incident date time
  iDate: null,
  iTime: null,

  complainDesc: null,
  // did try to self reolve
  selfResolve: null,

  // self resolve disscussion date time
  srDate: null,
  srTime: null,

  //complainee offer solution
  srSolution: false,
  srReplyDesc: null,
});
const rules = {
  complaintType: {
    required,
    minValue: minValue(1),
  },
  complainantID: {
    required: requiredIf(function (Model) {
      if (Model.complaintType > 0 && Model.complaintType <= 8) {
        return true;
      } else {
        return false;
      }
    }),
  },
  complaineeID: {
    required: requiredIf(function (Model) {
      let CT = Model.complaintType;
      if (CT == 1 || CT == 2 || CT == 5 || CT == 6 || CT == 9 || CT == 10) {
        return true;
      } else {
        return false;
      }
    }),
  },

  //incident date time
  iDate: {
    required,
  },
  iTime: {
    required,
  },
  complainDesc: {
    required,
    minLength: minLength(25),
    maxLength: maxLength(1600),
  },

  // self resolve disscussion date time
  srDate: {
    required: requiredIf(function (Model) {
      return Model.selfResolve;
    }),
  },
  srTime: {
    required: requiredIf(function (Model) {
      return Model.selfResolve;
    }),
  },
  //complainee offer solution
  srReplyDesc: {
    required: requiredIf(function (Model) {
      return Model.srSolution;
    }),
    maxLength: maxLength(1600),
  },
};

const v$ = useVuelidate(rules, form);

/*const compTypeError = computed(() => {
        const errors = [];
        if (!this.v$.complaintType.$dirty) return errors;
        !this.v$.complaintType.required &&
          errors.push("Complaint type is required.");
        !this.v$.complaintType.minValue &&
          errors.push("Complaint type is required.");
        return errors;
    })

    const complainantError = computed(() => {  const errors = [];
        if (!this.v$.form.complainantID.$dirty) return errors;
        !this.v$.form.complainantID.required &&
          errors.push("Please select a complainant");
        return errors;})

    const complaineeError = computed(() => { 
        const errors = [];
        if (!this.v$.form.complaineeID.$dirty) return errors;
        !this.v$.form.complaineeID.required &&
          errors.push("Please select a complainee");
        return errors;
    })
    const compDateError = computed(() => { 
        const errors = [];
        if (!this.v$.form.iDate.$dirty) return errors;
        !this.v$.form.iDate.required &&
          errors.push("Please select incident date.");
        return errors;
    })
    const compTimeError = computed(() => {  const errors = [];
        if (!this.v$.form.iTime.$dirty) return errors;
        !this.v$.form.iTime.required &&
          errors.push("Please select incident time.");
        return errors;
    })

    const compDescError = computed(() => {
        const errors = [];
        if (!this.v$.form.complainDesc.$dirty) return errors;
        !this.v$.form.complainDesc.required &&
          errors.push("Please enter complaint description.");
        !this.v$.form.complainDesc.minLength &&
          errors.push("Complaint description is too short. Min 25 characters");
        !this.v$.form.complainDesc.maxLength &&
          errors.push("Complaint description is too Long. Max 1600 characters");
        return errors;
     })
    const srDateError = computed(() => { 
        const errors = [];
        if (!this.v$.form.srDate.$dirty) return errors;
        !this.v$.form.srDate.required &&
          errors.push("Please select discussion date.");
        return errors;
    })
    const srTimeError = computed(() => { 
        const errors = [];
        if (!this.v$.form.srTime.$dirty) return errors;
        !this.v$.form.srTime.required &&
          errors.push("Please select discussion time.");
        return errors;
    })
    const srDescError = computed(() => {
        const errors = [];
        if (!this.v$.form.srReplyDesc.$dirty) return errors;
        !this.v$.form.srReplyDesc.required &&
          errors.push("Please enter complainee reply description.");
        !this.v$.form.srReplyDesc.maxLength &&
          errors.push(
            "Complainee reply description is too Long. Max 1600 characters"
          );
        return errors;
     })
*/
</script>

<style></style>
