import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import DashView from "../views/DashView.vue";
import SchoolInfoView from "../views/school/SchoolInfoView.vue";
import InstructorInfoView from "../views/instructor/InstructorInfoView.vue";
import ComplaintsDash from "@/views/complaints/ComplaintsDash.vue";
import RecordComplaint from "@/views/complaints/RecordComplaint.vue";
import ActiveComplaints from "@/views/complaints/ActiveComplaints.vue";
import ResolvedComplaints from "@/views/complaints/ResolvedComplaints.vue";
//feedbacks
import FeedbackView from "@/views/feedbacks/FeedbackView.vue";
import RecordFeedback from "@/views/feedbacks/RecordFeedback.vue";
//buy lessons
import BuyLessons from "@/views/topupLessons/BuyLessons.vue";
//bookings
import BookingsDash from "@/views/bookings/BookingsDash.vue";
import PaymentHistory from "@/views/bookings/PaymentHistory.vue";
import CompletedBookings from "@/views/bookings/CompletedBookings.vue";
import UpcomingBookings from "@/views/bookings/UpcomingBookings.vue";
import BookLesson from "@/views/bookings/BookLesson.vue";
import ManageBooking from "@/views/bookings/ManageBooking.vue";


const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInTop",
      leaveClass: "animate__animated animate__faster animate__slideOutLeft",
      level: 0
    },
  },
  {
    path: "/dash",
    name: "dash",
    component: DashView,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInLeft",
      leaveClass: "animate__animated animate__faster animate__slideOutRight",
      level: 1
    },
  },
  {
    path: "/schoolinfoview",
    name: "SchoolInfoView",
    component: SchoolInfoView,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInRight",
      leaveClass: "animate__animated animate__faster animate__slideOutLeft",
      level: 2
    },
  },
  {
    path: "/instructorinfoview",
    name: "InstructorInfoView",
    component: InstructorInfoView,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInRight",
      leaveClass: "animate__animated animate__faster animate__slideOutLeft",
      level: 2
    },
  },
  {
    path: "/complaintsdash",
    name: "ComplaintsDash",
    component: ComplaintsDash,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInRight",
      leaveClass: "animate__animated animate__faster animate__slideOutLeft",
      level: 2
    },
  },
  {
    path: "/complaintsdash/recordcomplaint",
    name: "ComplaintsDash.RecordComplaint",
    component: RecordComplaint,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInRight",
      leaveClass: "animate__animated animate__faster animate__slideOutLeft",
      level: 3
    },
  },
  {
    path: "/complaintsdash/activecomplaints",
    name: "ComplaintsDash.ActiveComplaints",
    component: ActiveComplaints,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInRight",
      leaveClass: "animate__animated animate__faster animate__slideOutLeft",
      level: 3
    },
  },
  {
    path: "/complaintsdash/resolvedcomplaints",
    name: "ComplaintsDash.ResolvedComplaints",
    component: ResolvedComplaints,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInRight",
      leaveClass: "animate__animated animate__faster animate__slideOutLeft",
      level: 3
    },
  },
  //feedbacks
  {
    path: "/feedbackview",
    name: "FeedbackView",
    component: FeedbackView,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInRight",
      leaveClass: "animate__animated animate__faster animate__slideOutRight",
      level: 2
    },
  },
  {
    path: "/feedbackview/recordfeedback",
    name: "FeedbackView.RecordFeedback",
    component: RecordFeedback,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInRight",
      leaveClass: "animate__animated animate__faster animate__slideOutLeft",
      level: 3
    },
  },
  //buy lessons
  {
    path: "/buylessons",
    name: "BuyLessons",
    component: BuyLessons,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInRight",
      leaveClass: "animate__animated animate__faster animate__slideOutRight",
      level: 2
    },
  },
  //bookings
  {
    path: "/bookingsdash",
    name: "BookingsDash",
    component: BookingsDash,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInRight",
      leaveClass: "animate__animated animate__faster animate__slideOutRight",
      level: 2
    },
  },
  {
    path: "/bookingsdash/paymenthistory",
    name: "BookingsDash.PaymentHistory",
    component: PaymentHistory,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInRight",
      leaveClass: "animate__animated animate__faster animate__slideOutRight",
      level: 3
    },
  },
  {
    path: "/bookingsdash/completedbookings",
    name: "BookingsDash.CompletedBookings",
    component: CompletedBookings,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInRight",
      leaveClass: "animate__animated animate__faster animate__slideOutRight",
      level: 3
    },
  },
  {
    path: "/bookingsdash/upcomingbookings",
    name: "BookingsDash.UpcomingBookings",
    component: UpcomingBookings,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInRight",
      leaveClass: "animate__animated animate__faster animate__slideOutRight",
      level: 3
    },
  },
  {
    path: "/bookingsdash/booklesson",
    name: "BookingsDash.BookLesson",
    component: BookLesson,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInRight",
      leaveClass: "animate__animated animate__faster animate__slideOutRight",
      level: 3
    },
  },
  {
    path: "/bookingsdash/managebooking",
    name: "BookingsDash.ManageBooking",
    component: ManageBooking,
    meta: {
      enterClass: "animate__animated animate__faster animate__slideInRight",
      leaveClass: "animate__animated animate__faster animate__slideOutRight",
      level: 3
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
