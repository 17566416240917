<template>
  <div>
    <v-app>
      <AppToolBar />
      <v-main>
        <v-container>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-card
                prepend-avatar="https://cdn.vuetifyjs.com/images/john.jpg"
                class="mx-auto"
                :title="`Hi ${storeLeanerData.name}`"
                :subtitle="`Pupil of ${storeLeanerData.instructorInfo.name}` "
                color="blue-grey-lighten-4"
              >
                <template v-slot:prepend>
                  <v-avatar>
                    <v-img
                      alt="You"
                      src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
                      size="x-large"
                    ></v-img>
                  </v-avatar>
                </template>
                <v-card-text>
                  Lessons Completed : {{ storeLeanerData.count }} <br />
                  Scheduled lessons: {{ storeLeanerData.doubleCount }} <br />
                  Avaiable lessons: {{ storeLeanerData.avaialabeLessons }}
                  <br />
                </v-card-text>
              </v-card>
            </v-col>
            <!--v-col cols="12">
                            <v-card
                                class="mx-auto"
                                outlined
                                prepend-icon="$vuetify"
                                append-icon="mdi-chevron-right"
                                :subtitle="count"
                                title="Welcome to Vuetify"
                                width="100%"
                                @click="inc()"
                            >
                            </v-card>
                        </v-col-->
            <v-col cols="12" v-for="panel in panels" :key="panel.id">
              <v-card
                class="mx-auto"
                variant="outlined"
                append-icon="mdi-chevron-right"
                :prepend-icon="panel.ico"
                :subtitle="panel.sub"
                :title="panel.text"
                :to="panel.link"
                width="100%"
                @click="storeLeanerData.incriment"
                :disabled="panel.isReadonly"
              >
                <template v-slot:prepend>
                  <v-icon
                    :icon="panel.ico"
                    color="primary"
                    size="x-large"
                  ></v-icon>
                </template>
              </v-card>
            </v-col>
            <!--v-col cols="12">
              <v-card color="#385F73">
                <v-card-title class="text-h5"> Shortcuts </v-card-title>

                <v-card-subtitle>
                  Listen to your favorite artists and albums whenever and
                  wherever, online and offline.
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn text="Listen Now" variant="text"></v-btn>
                </v-card-actions>
              </v-card>
            </v-col-->
          </v-row>
        </v-container>
      </v-main>
      <BottomNav />
    </v-app>
  </div>
</template>

<script setup>
import { useLearnerStore } from "@/store/learner-store";
import AppToolBar from "@/components/AppToolBar";
import BottomNav from "@/components/BottomNav";

const storeLeanerData = useLearnerStore();

const panels = [
  {
    id: 1,
    text: "Favorites",
    sub: "Most used options",
    link: "",
    ico: "mdi-heart-outline",
    isReadonly: false,
  },
  {
    id: 2,
    text: "Bookings",
    sub: "Everything related to lessons & bookings",
    link: "/bookingsdash",
    ico: "mdi-calendar-blank-outline",
    isReadonly: false,
  },
  {
    id: 3,
    text: "Top-up Account",
    sub: "Buy more lessons",
    link: "/buylessons",
    ico: "mdi-cart-plus",
    isReadonly: false,
  },
  {
    id: 4,
    text: "Feedbacks",
    sub: "Give some feedback for your lessons",
    link: "/feedbackview",
    ico: "mdi-comment-quote-outline",
    isReadonly: false,
  },
 
  {
    id: 6,
    text: "Instructor",
    sub: "See Driving Instructor Details",
    link: "/instructorinfoview",
    ico: "mdi-human-male-board",
    isReadonly: false,
  },
  {
    id: 7,
    text: "School",
    sub: "See Driving School Details",
    link: "/schoolinfoview",
    ico: "mdi-school-outline",
    isReadonly: false,
  },
  {
    id: 5,
    text: "Compalints",
    sub: " ",
    link: "/complaintsdash",
    ico: "mdi-emoticon-sad-outline",
    isReadonly: false,
  },
  {
    id: 8,
    text: "Help Center",
    sub: "How can we help today",
    link: "",
    ico: "mdi-help-circle-outline",
    isReadonly: true,
  },
];
</script>

<style lang="scss" scoped></style>
