<template>
    <div>
      <v-app>
        <AppToolBar :isBack="true" link="/dash" />
        <v-main>
          <v-container>
            <v-row dense>             
              <v-col cols="12" v-for="panel in panels" :key="panel.id">
                <v-card
                  class="mx-auto"
                  variant="outlined"
                  append-icon="mdi-chevron-right"
                  :prepend-icon="panel.ico"
                  :subtitle="panel.sub"
                  :title="panel.text"
                  :to="panel.link"
                  width="100%"
                  :disabled="panel.isReadonly"
                >
                  <template v-slot:prepend>
                    <v-icon
                      :icon="panel.ico"
                      color="primary"
                      size="x-large"
                    ></v-icon>
                  </template>
                </v-card>
              </v-col>              
            </v-row>
          </v-container>
        </v-main>
      </v-app>
    </div>
  </template>
  
  <script setup>
  import AppToolBar from "@/components/AppToolBar";
  

  
  const panels = [
    {
      id: 1,
      text: "Make a complain",
      sub: "Is something not right, let us know",
      link: "/complaintsdash/recordcomplaint",
      ico: "mdi-typewriter",
      isReadonly: false,
    },
    {
      id: 2,
      text: "Active Complaints",
      sub: "See all active complaints here",
      link: "/complaintsdash/activecomplaints",
      ico: "mdi-book-open-variant-outline",
      isReadonly: false,
    },
    {
      id: 3,
      text: "Resolved Complaints",
      sub: "See all reolved complaints here",
      link: "/complaintsdash/resolvedcomplaints",
      ico: "mdi-check",
      isReadonly: false,
    },
  ];
  </script>
  
  <style lang="scss" scoped></style>
  